import React from 'react'
import Link from 'gatsby-link'
import Layout from '../../components/Layout'
import Table from 'react-bootstrap/Table'

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Kundeoppdatering</h1>
              <p>For å legge inn en kundeendering, vennligst se:
              </p>
              <ul>
                <li>
                  <Link to="/contact/change/">KUNDEENDRING</Link>
                </li>
              </ul>

              <h2>Kontakt informasjon</h2>
              <h3>Generelle henvendelser</h3>
              <h3>Tlf. 32 74 50 00</h3>
              <h3>epost: post@uvdalkraft.no</h3>

                <Table striped bordered hover>
                    <tr>
                        <th >Navn</th>
                        <th>Telefon</th>
                        <th>E-post</th>
                    </tr>
                    <tr>
                        <td>Daglig leder, Tilsyn/DLE: Frank Skarpås</td>
                        <td>32 74 50 00/ 416 00 003</td>
                        <td>frank.skarpas@uvdalkraft.no</td>
                    </tr>
                    <tr>
                        <td>Nett: Leif Harald Johansen</td>
                        <td>32 74 50 00/ 416 00 005</td>
                        <td>leif.harald.johansen@uvdalkraft.no</td>
                    </tr>
                    <tr>
                        <td>Fiber: Peter N. Halsteinsgård</td>
                        <td>32 74 50 00/ 416 00 007</td>
                        <td>peter.halsteinsgård@uvdalkraft.no</td>
                    </tr>
                    <tr>
                        <td>Avdelingsleder: Anne Sønsterud</td>
                        <td>32 74 50 00</td>
                        <td>anne.sonsterud@uvdalkraft.no</td>
                    </tr>
                     <tr>
                        <td>Energimontør: Per Martin Grøtjorden</td>
                        <td>953 06 560</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Energimontør: Dan Marcus Jensen</td>
                        <td>416 00 009</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Energimontør: Knut Morten S. Loftsgård</td>
                        <td>414 00 004 </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Renholder: Lina Janciene</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Lærling: Petter A. Undebakke</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>I permisjon : Plan: Ola Grøtjorden</td>
                        <td>32 75 00 00 / 416 00 006</td>
                        <td>ola.grotjorden@uvdalkraft.no</td>
                    </tr>
                </Table>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
